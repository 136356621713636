import React from 'react';
import { Link } from 'react-router-dom';

import MRINDIANCODER from "../images/logo.png";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
import Mapg from '../pages/Mapg';

function Footer() {
  return (


    <div className="backgroundfooter">
      <br></br>
      <br />
      {/* <div className="p-3 mb-0 bg-dark text-white"> */}

      <div className="album py-3">

        <div className="container">

          <div className="row">
            <div className="col-md-4">

              <h4 style={{ color: "white" }}>Stay Connected</h4>
              <hr />

              <ul className="list-unstyled">

                <h6 style={{ color: "white" }} className="card-text "> <b>UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED</b></h6>

                <p style={{ color: "white" }} className="card-text mb-2">Ajhapur High Road Chowmatha, Near Eye Hospital</p>
                <p style={{ color: "white" }} className="card-text mb-2">PO: Ajhapur, PS: Jamalpur,</p>
                <p style={{ color: "white" }} className="card-text mb-2">Dist: Purba Bardhaman, WB, India-713401</p>


                <a href="tel: +91  8017529256" className="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91  8017529256</a>
                <br />

                <a href="tel: +91  8777414566" className="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91  8777414566</a><br />
                <ReactWhatsapp number="+91 8250136530" className="button1 mb-2" message="I am Interested"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg> +91 8250136530</ReactWhatsapp>
                <br />

                <ReactWhatsapp number="+91 8017529256" className="button1" message="I am Interested"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg> +91 8017529256</ReactWhatsapp>
              </ul>
            </div>

            <div className="col-md-2">
              <h6 style={{ color: "white" }}>Product</h6>
              <hr />
              <ul className="list-unstyled">




                <Link style={{ color: "white" }} to="/potato" target='_blank'>
                  <li style={{ color: "white" }} className="mb-2"><a ><small>Potato</small></a></li></Link>
                <Link to="/rice" target='_blank'>
                  <li style={{ color: "white" }} className="mb-2"><a ><small> Rice</small></a></li></Link>
                <Link style={{ color: "white" }} to="/mushroom" target='_blank'>
                  <li style={{ color: "white" }} className="mb-2"><a><small>Masroom</small></a></li></Link>
                <Link style={{ color: "white" }} to="/carrot" target='_blank'>
                  <li className="mb-2"><a><small>Carrot</small></a></li></Link>
                <Link style={{ color: "white" }} to="/capsicum" target='_blank'>

                  <li style={{ color: "white" }} className="mb-2"><a><small>Capsicum</small></a></li></Link>
                <Link to="/pumpkin" target='_blank'>

                  <li style={{ color: "white" }} className="mb-2"><a><small>Pumkin</small></a></li></Link>



              </ul>

            </div>
            <div className="col-md-2">

              <h6 style={{ color: "white" }}>Information</h6>
              <hr />

              <ul class="list-unstyled">

                <Link style={{ color: "white" }} to="/">
                  <li style={{ color: "white" }} className="mb-2"><small>Home </small></li></Link>
                <Link style={{ color: "white" }} to="/aboutus">
                  <li style={{ color: "white" }} className="mb-2"><small>About </small></li></Link>
                <Link style={{ color: "white" }} to="/certificate">
                  <li style={{ color: "white" }} className="mb-2"><small>Certificate </small></li></Link>
                <Link style={{ color: "white" }} to="/blog">
                  <li style={{ color: "white" }} className="mb-2"><small>Blog </small></li></Link>
              </ul>
            </div>
            <div className="col-md-2">

              <h6 style={{ color: "white" }}>Contact Us</h6>
              <hr />

              <ul class="list-unstyled">

                <Link style={{ color: "white" }} to="/contactus">
                  <li style={{ color: "white" }} className="mb-2"><small>Contact </small></li></Link>

              </ul>
            </div>

            <div className="col-md-2">
              <h6 style={{ color: "white" }}>Social Link</h6>
              <hr />
              <ul className="list-unstyled">
                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.facebook.com/profile.php?id=61554915975871&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg> <small> Facebook</small></a> </li>

                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg> <small> Youtube</small></a> </li>
                        <br/>
                        <br/>
                  <h6 style={{ color: "white" }}>GOOGLE MAP</h6>
              <hr style={{ color: "white" }}/>
                  <div className="album py-1">
                <div className="container">

                  <div className="row">

                    {/* <iframe className="iframe-fluid mb-2" src="https://www.google.com/maps/embed?pb=!4v1690479647357!6m8!1m7!1sZ6g5xmOl_2Z01-ULJYumcA!2m2!1d23.98224995602774!2d88.63313116394505!3f160.65243800200318!4f6.915038183872269!5f0.7820865974627469"  height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                    <iframe width="250" height="150" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=42JX+5W%20Uniseeds%20Agro%20India%20Export%20Pvt.Ltd,%20Ajhapur,%20West%20Bengal%20713401%20+(42JX+5W%20Uniseeds%20Agro%20India%20Export%20Pvt.Ltd,%20Ajhapur,%20West%20Bengal%20713401)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

                  </div>
                </div>
                </div>




              </ul>


            </div>





          </div>
        </div>
      </div>


      <br></br><br />

      <div className="album py-3">

        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <Link to="/">
                <h4>UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED</h4>
              </Link>
            </div>

            <div className="col-md-4">
              <p style={{ color: "white" }}> Copyright © 2023, UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED</p>

            </div>


          </div>
        </div>
      </div>

    </div>
    //  </div>





  );
}
export default Footer;