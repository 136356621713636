import React from 'react'
import {Link} from "react-router-dom";
import Logo from "../images/uniseedsagro.png";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
<div className='fixed-top'>
<nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid ">
    <Link to="/">
   <img src={Logo} width="83px" height="79px" alt='logo'/>
   </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active me-2" aria-current="page" href="#">Home</Link>
        </li>

        <li className="nav-item">
          <Link to="/aboutus" className="nav-link me-2" aria-current="page" href="#">About Us</Link>
        </li>
        <li className="nav-item">
          <Link to="/Certificatepage" className="nav-link me-2" aria-current="page" href="#">Certificate</Link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle me-2" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Products
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/potato" className="dropdown-item" href="#">Potato</Link></li>
            <li><Link to="/rice" className="dropdown-item" >Rice</Link></li>
            <li><Link to="/mushroom" className="dropdown-item" href="#">Mushroom</Link></li>
            <li><Link to="/carrot" className="dropdown-item" href="#">Carrot</Link></li>
            <li><Link to="/capsicum" className="dropdown-item" href="#">Capsicum</Link></li>
            <li><Link to="/pumpkin" className="dropdown-item" href="#">Pumkin</Link></li>
            <li><Link to="/rasogolla" className="dropdown-item" href="#">Rasogolla</Link></li>
            <li><Link to="/chili" className="dropdown-item" href="#">Chili</Link></li>
            <li><Link to="/mango" className="dropdown-item" href="#">Mango</Link></li>
            <li><Link to="/beans" className="dropdown-item" href="#">Beans</Link></li>
            <li><Link to="/Peas" className="dropdown-item" href="#">Peas</Link></li>
            <li><Link to="/Drumstick" className="dropdown-item" href="#">Drumstick</Link></li>
            <li><Link to="/Onion" className="dropdown-item" href="#">Onion Sprouts</Link></li>
         
            
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/blog" className="nav-link me-2" aria-current="page" href="#">Blogs</Link>
        </li>
        <li className="nav-item">
          <Link to="/contactus" className="nav-link" aria-current="page" href="#">Contact Us</Link>
        </li>
    
   
      </ul>
  
        <a href='tel: 8017529256' className="button1" type="submit">Call Now</a>
   
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar