import React, { useState, useEffect } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
// import "./Gallery.css";


// import Videogallery from "../pages/Videogallery";

function Certificate(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [certificate, setCertificate] = useState([])
    const navigate = useNavigate()

    const certificateCollectionRef = collection(db, "Certificate");
    useEffect(() => {

        const getCertificate = async () => {
            const data = await getDocs(certificateCollectionRef);
            setCertificate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getCertificate()
    }, [])
    return (

        <>
            <Navbar />

            <br />
           
          
         


         




            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {certificate.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.img.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((ssagroindia) => {
                            return (

                                <div className="col-md-2 mt-7" >
                                    <div className="card mb-4 border-White box shadow">
                                        <img className="card-img-top" src={ssagroindia.img} alt="certificate" />
                                      
                                        




                                           

                                                {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}




                                          
                                      
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={certificate.length}
                /> */}
                </div>
            </div>


        </>

    );
}

export default Certificate;