import React from 'react'
import Navbar  from '../inc/Navbar'
import Pumpkinpage from "../images/pumpkinpage.jpg"
function Pumpkin() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <img src={Pumpkinpage} className="card-img-top" alt="ssagroindiapvtltd-pumpkin" />
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Indian Pumpkin Exporters</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>In India, pumpkin holds cultural significance beyond its culinary applications. Often associated
with festivals like Diwali and Navratri, it symbolizes prosperity and abundance. Pumpkin, known
as "Kaddu" in Hindi, is a vibrant and versatile vegetable that has firmly entrenched itself in the
heart of Indian cuisine. This humble member of the gourd family boasts a rich history and an array
of culinary uses, making it a staple in households across the subcontinent.Apart from its delightful
taste, pumpkin offers numerous health benefits. Its fiber content aids digestion, and the high
water content helps keep the body hydrated. The presence of antioxidants supports immune
function, making it a valuable addition to one's diet.</p>
<h4>A. Calabaza pumpkin:-
</h4>
<p className='texts'>Calabaza pumpkins are known for their sweet and nutty flavor, as well as their dense and smoothtextured flesh.These pumpkins are often used in a variety of dishes, including soups, stews, and
desserts. The flesh can be roasted, mashed, or pureed for different culinary applications. In some
regions, calabaza is a key ingredient in traditional recipes such as soups like "sopa de calabaza"
(pumpkin soup) or sweets like "dulce de calabaza" (candied pumpkin).</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Pumpkin