import React from 'react'
import Navbar  from '../inc/Navbar'
import Pumpkinpage from "../images/pumpkinpage.jpg"
function Chili() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>

        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >1. Green Chilly:-
 </h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>Green chili refers to a variety of chili peppers that are harvested while still green. These peppers
can vary in terms of spiciness, flavor, and size, depending on the specific type. Some common
green chili varieties include Anaheim, poblano, serrano, and jalapeño peppers.Green chilies are
often used in various cuisines around the world to add heat and flavor to dishes. They can be
used fresh, roasted, or dried, and are popular in salsas, sauces, stews, and as toppings for various
foods. The level of spiciness in green chilies can range from mild to hot, so it's essential to consider
personal taste preferences when incorporating them into recipes.In addition to their spiciness,
green chilies also contain vitamins, minerals, and antioxidants, making them a nutritious addition
to meals.
</p>
<center><h1 style={{color:"green"}} >2. Kashmiri chili :-
 </h1></center>

<p>            Kashmiri chili refers to a variety of chili peppers that are cultivated in the Kashmir region of
India. These chilies are known for their vibrant red color and are a key ingredient in Kashmiri
cuisine, which is renowned for its rich and aromatic flavors. Kashmiri red chili powder is a
common spice in Indian and Kashmiri cooking and is sometimes used to marinate meats or in
the preparation of spice blends. It's important to note that while these chilies add a beautiful
red hue to the food, they are generally milder compared to some other chili varieties, making
them suitable for those who prefer a less spicy experience.
</p>

        </div>
        </div>
        </div>
    </div>
  )
}

export default Chili