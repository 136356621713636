import React, { useState, useEffect }  from 'react';

import {useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'


function Slider() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [slider, setSlider] = useState([])
      const navigate = useNavigate()
  
      const sliderCollectionRef = collection(db, "Slider");
      useEffect(() => {
  
          const getSlider = async () => {
              const data = await getDocs(sliderCollectionRef);
              setSlider(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSlider()
      }, [])
  return (
    <div>

{slider.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((ssagroindia) => { return (
       
       
                       
                            <div className="card-body">
                                   
                                   <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>

  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={ssagroindia.slider1} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h3>{ssagroindia.title1}</h3>
        <p>{ssagroindia.des1}</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={ssagroindia.slider2} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>{ssagroindia.title2}</h5>
        <p>{ssagroindia.des2}</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={ssagroindia.slider3} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>{ssagroindia.title2}</h5>
        <p>{ssagroindia.des2}</p>
      </div>
    </div>
 
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  
                                
                               
                              
                                {/* <h5 style={{color: "Darkblue"}}classNameName="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}classNameName="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={slider.length}
                /> */}
      
    </div>
  )
}

export default Slider