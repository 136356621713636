import React from 'react'
import Navbar  from '../inc/Navbar'
import AddSubscribe from "../AddSubscribe";
import {Link} from "react-router-dom";

function Subscribe() {
  return (
    <div>

        <Navbar/>
        <br/>
        <br/>
     
        
<div className='backcolor'>
<br />
<br/>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <h1>Subscribes</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{color:"white"}} to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Subscribes</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
            <br/>
        <center>
     <h1 style={{color:"green"}} ><b >Subscribes</b></h1>

     </center>
     <br/>
     <AddSubscribe/>
<br/>
<br/>
    </div>
  )
}

export default Subscribe