import React from 'react'
import Navbar from '../inc/Navbar'
import Ricepage from "../images/ricepage.jpg"
function Rice() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <img src={Ricepage} className="card-img-top" alt="ssagroindiapvtltd-Rice" />
            <br />
            <br />
            <br />
            <br />
            <center><h1 style={{ color: "green" }} >Indian Rice Exporters</h1></center>
            <br />

            <div className='album py-2'>
                <div className='container'>
                    <div className='row'>
                        <p className='texts'>In India, rice is more than just a grain; it's a cultural cornerstone. It plays a central role in religious
ceremonies, traditional rituals, and festive celebrations.Rice, an integral part of Indian culinary
heritage, is not just a staple but a symbol of cultural diversity and richness. Celebrated for its
versatility and nutritional benefits, rice holds a sacred place in the hearts and kitchens of every
Indian household.Beyond its delicious taste and cultural significance, rice is a nutritional
powerhouse. A rich source of carbohydrates, it provides essential energy for daily activities.
Brown rice, with its bran and germ intact, offers additional fiber, vitamins, and minerals. Rice is
gluten-free, making it suitable for individuals with dietary restrictions, and its versatility allows
for a range of healthy preparations.Rice is more than just a grain in Indian cuisine; it's a thread
that weaves through the fabric of tradition, connecting generations and regions. As we savor the
diverse flavors and aromas of this staple, let's appreciate the role rice plays in shaping the culinary
landscape of India, making each meal a celebration of culture, community, and nourishment</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h3><b>A. Arborio rice:-</b></h3>
                        <p>Arborio rice is a type of short-grain rice primarily grown in the Po Valley region of Italy. It is named
after the town of Arborio, which is located in the Po Valley. Arborio rice is known for its high starch
content and the ability to absorb liquids, making it a popular choice for risotto, a classic Italian
dish.Arborio rice has short, round grains that are plump and chalky white in appearance.The high
starch content of Arborio rice is essential for creating the creamy texture characteristic of risotto.
As the rice cooks, the outer layer releases starch, creating a smooth, velvety consistency.</p>
<br/>
<h4>B. Basmati rice:-</h4>
<p>Basmati rice is a long-grain rice variety that is known for its distinctive aroma, long grain, and
fluffy texture when cooked. It is primarily grown in the Indian subcontinent, particularly in the
Himalayan foothills of India and Pakistan.Basmati rice grains are long, slender, and separate easily
after cooking. When cooked, the grains remain firm and do not stick together, resulting in a fluffy
texture.Basmati rice is traditionally grown in the fertile plains of the Himalayan region. The unique
climate, soil, and water conditions in this area contribute to the distinctive qualities of Basmati
rice.
</p>
<h4>C. Black Rice</h4>
<p>Black rice, also known as forbidden rice or purple rice, is a type of rice that is deep black or purple
in color. It is often associated with various health benefits and has a unique nutty flavor.Black rice
is rich in anthocyanins, the same antioxidants found in blueberries and blackberries.Black rice is
a good source of essential vitamins and minerals, including iron and zinc.The dark color of black
rice comes from pigments called anthocyanins, which are also found in certain fruits and
vegetables.Black rice was traditionally considered rare and reserved for royalty in ancient China,
earning it the name "forbidden rice."
</p>
<h4>D. Bomba rice:-</h4>
<p>Bomba rice , also known as "Bomba de Calasparra" or simply "Bomba," is a type of short-grain
rice primarily grown in the Calasparra region of Murcia, Spain. It is renowned for its exceptional
quality and is considered one of the best rice varieties for paella, a traditional Spanish dish.Bomba
rice has a high absorption capacity, meaning it can absorb a significant amount of liquid while still
maintaining its firm texture.Bomba rice has a nutty flavor that adds to the overall taste of the
dish.The grains of Bomba rice are short and round, with a unique ability to expand in width rather
than length during cooking.</p>
<h4>E.Brown rice:-</h4>
<p>Brown rice is a whole grain rice that has only the outermost layer, the hull, removed. This makes
it a more nutritious option compared to white rice, which has had the bran and germ layers
removed in addition to the hull. The bran layer in brown rice contains fiber, essential oils, and
important nutrients like B vitamins, minerals, and antioxidants.Brown rice is a good source of
complex carbohydrates, fiber, and various essential nutrients such as manganese, selenium,
magnesium, and some B vitamins.There are different varieties of brown rice, including long-grain,
medium-grain, and short-grain. Each variety has its own texture and flavor profile.Brown rice
generally takes longer to cook than white rice due to its higher fiber content and the presence of the bran layer.Like other grains, brown rice should be stored in a cool, dry place to prevent
spoilage.</p>
<h4>F.Jasmine rice</h4>
<p>Jasmine rice is a type of long-grain rice known for its fragrant aroma and slightly sticky texture
when cooked. It is primarily grown in Thailand, Cambodia, Laos, and Vietnam, although it is also
cultivated in other regions.One of the distinctive features of Jasmine rice is its natural fragrance,
often described as having a floral or popcorn-like aroma. This aroma is more pronounced when
the rice is freshly cooked.</p>
<h4>G. Long-grain white rice:-
</h4>
<p>Long-grain white rice is a type of rice that has long, slender grains. It is one of the most common
varieties of rice and is widely consumed around the world.Long-grain rice has grains that are
typically four to five times longer than they are wide. The length of the grains can vary slightly
depending on the specific variety.Due to its fluffy texture and mild flavor, long-grain white rice is
versatile and pairs well with a wide range of dishes. It is a staple in many cuisines and can be used
in both savory and sweet recipes.Like other types of rice, long-grain white rice is a good source
of carbohydrates. It is low in fat and contains some protein. It is often enriched with iron and
other nutrients to improve its nutritional profile.

</p>
<h4>H. Parboiled rice:-</h4>
<p>Parboiled rice is a type of rice that has been partially boiled in the husk. This process involves
soaking, steaming, and drying the rice before milling it. The term "parboiled" is derived from
"partially boiled."The process helps retain more nutrients in the rice compared to regular white
rice, as some nutrients from the bran and germ diffuse into the endosperm during parboiling.</p>
                    </div>
                </div>
            </div>

          
            
            <br />
            <br />

        </div>
    )
}

export default Rice