import React from 'react'
import Navbar  from '../inc/Navbar'
function Beans() {
  return (
    <div>
        <Navbar/>
        <br/>
   
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Beans</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>French beans, also known as green beans or string beans, come in various varieties with different 
characteristics such as size, color, and taste. Some common varieties include:
</p>
<h4>1. French Filet Beans:
</h4>
<br/>

<p>These are slender, stringless beans that are tender and have a delicate flavor.</p>
<h4>2. Haricot Vert:</h4>

<p>Similar to French filet beans, haricot vert is a slender and tender variety of green bean.
</p>
<h4>3. Blue Lake Beans: </h4>

<p>These beans are known for their straight, smooth pods and are popular for both fresh consumption and 
canning.</p>

<h4>4. Provider Beans: </h4>

<p>This is a classic green bean variety known for its productivity and reliable crop</p>
<h4>5. Kentucky Wonder Beans:</h4>
<p>A pole bean variety with a distinct flavor, often used in traditional recipes.</p>
<h4>6. Top Crop Beans:</h4>
<p>These are bush beans with a concentrated harvest period, making them suitable for canning or freezing.</p>

<br/>




        </div>
        </div>
        </div>
    </div>
  )
}

export default Beans