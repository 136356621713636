import React from 'react'
import Navbar from "../inc/Navbar";
import "./About.css";
import { Link } from "react-router-dom";
import Aboutpage from './Aboutpage';
import Mission from "../images/mission.jpg";
import Vision from "../images/vision.jpg";
import Director from "../images/director.jpg";
import MD from "../images/md.jpg";
import cmd from "../images/cmd.jpg";
import ScrollToTop from "react-scroll-to-top";

function Aboutus() {
  return (
    <div>
      <Navbar />
      <br />
      <br />


      <ScrollToTop smooth top="100" color="maroon" />

      <div className="aboutimage">

        <div className="album py-1">

          <div className="container">


            <h1 style={{ color: "Darkblue" }} className='mb-2'><b>ABOUT US</b></h1>
            <nav aria-label="breadcrumb">
              <ol style={{ color: "Darkblue" }} className="breadcrumb">
                <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link style={{ color: "Darkblue" }} to="/" ><b>Home</b></Link></li>
                <li style={{ color: "Darkblue" }} className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>

          </div>
        </div>

      </div>


      <Aboutpage />
      <br />



   

     


      <div className="album py-3">

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="alert alert-info" role="alert">

                <div class="row g-0">
                  <div class="col-md-4">
                    <img src={Mission} class="img-fluid rounded-start " alt="Mission" />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title mt-4 mb-2"><b>OUR MISSION</b></h5>
                      <p class="card-text texts">To empower communities through sustainable agriculture, Uniseeds Agro India Export Private Limited is committed to providing high-quality, locally sourced vegetables that nourish and promote well-being.  Through innovation, ethical business practices, and a dedication to customer satisfaction, we aim to be a leading force in the Indian vegetable industry, contributing to the growth of local economies and fostering a healthier, more sustainable world.</p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="alert alert-danger" role="alert">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img src={Vision} class="img-fluid rounded-start " alt="Vision" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mt-4 mb-2"><b>OUR VISION</b></h5>
                      <p className="card-text texts">Empowering Lives through Sustainable Agriculture: Our vision is to be a leading Indian vegetable company that revolutionizes the agricultural landscape by promoting sustainable farming practices. We aspire to cultivate a healthier and more prosperous society by providing high-quality, locally grown vegetables that are not only delicious but also contribute to the well-being of our communities.
                        <br />
                        <br />
                      </p>

                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="album py-3">

<div className="container">
  <div className="row">
    <div className="col-md-4">
      <img src={Director} className="img-fluid rounded-start mb-3" alt="..." />
      <div className="card-body">
        <center>
          <h4 className="card-title mb-2"><b>DIRECTOR</b></h4>
          <h5 className="card-text">Riajuddin Mondol</h5>
        </center>
      </div>
    </div>
    <div className="col-md-4">
      <img src={MD} className="img-fluid rounded-start mb-3" alt="..." />
      <div className="card-body">
        <center>
          <h4 className="card-title mb-2"><b>MD</b></h4>
          <h5 className="card-text">Sanjay Nag</h5>
        </center>
      </div>
    </div>
    <div className="col-md-4">
      <img src={cmd} className="img-fluid rounded-start mb-3" alt="..." />
      <div className="card-body">
        <center>
          <h4 className="card-title mb-2"><b>CMD</b></h4>
          <h5 className="card-text">Firdousi Begum</h5>
        </center>
      </div>
    </div>
  </div>
</div>
</div>
<br/>
<br/>
      <center><h2 style={{ color: "green" }} >Who We Are?</h2></center>

      <div className="album py-2">

        <div className="container">
          <div className="row">
            <p style={{ color: "green" }} className='texts'>Welcome to our website and first of all the first identity of all of us is that we are Indians. Our most important goal is to give you all the best quality and healthy life.  Our only goal is to bring you the best products for everyday use.We believe that our goal will be successful for you one day.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutus