import React from 'react'

function Mapg() {
    return (
        <div>


            <div className="container">

                <div className="row">

                    {/* <iframe className="iframe-fluid mb-2" src="https://www.google.com/maps/embed?pb=!4v1690479647357!6m8!1m7!1sZ6g5xmOl_2Z01-ULJYumcA!2m2!1d23.98224995602774!2d88.63313116394505!3f160.65243800200318!4f6.915038183872269!5f0.7820865974627469"  height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                    <iframe width="520" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=42JX+5W%20Uniseeds%20Agro%20India%20Export%20Pvt.Ltd,%20Ajhapur,%20West%20Bengal%20713401%20+(42JX+5W%20Uniseeds%20Agro%20India%20Export%20Pvt.Ltd,%20Ajhapur,%20West%20Bengal%20713401)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>



                </div>
            </div>

            <br />
        </div>
    )
}

export default Mapg