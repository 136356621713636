import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar  from "../inc/Navbar";



// import { Typewriter } from 'react-simple-typewriter' 




function Certificatepage () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [certificatepage, setCertificatepage] = useState([])
      const navigate = useNavigate()
  
      const certificatepageCollectionRef = collection(db, "Certificatepage");
      useEffect(() => {
  
          const getCertificatepage = async () => {
              const data = await getDocs(certificatepageCollectionRef);
              setCertificatepage(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getCertificatepage()
      }, [])
    return (
      
<>
<Navbar/>
<br/>
<br/>

<br/>


<div className='backcolor'>
<br />
<br/>

            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <h1>Certificate Page</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{color:"white"}} to="/" >Home</Link></li>
                                <li style={{color:"white"}} className="breadcrumb-item active" aria-current="page">Certificate</li>
                                <br/>
                                <br/>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
<br/>
            <center><h2><b>Certifications</b></h2></center>
            <br/>
       
            <div className="album py-0">
        
            <div className="container">
            <p>This has allowed us to be accredited to a number of quality and food safety certifications. we make sure that everything is done in accordance with the stringent protocols. We are an Indian government-certified exporter of food products.</p>
            </div>
            </div>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search..." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {certificatepage.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 

    
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((certificatepage) => { return (
       
            <div className="col-md-2" >
                        <div className="card mb-5 border-White box shadow">
                            {/* <button className='button2'>{certificatepage.date}</button> */}
                        <img onClick={() => {navigate(`/Photocertificate/${certificatepage.id}`)}} className="card-img-top mb-2" src={certificatepage.img}   alt={certificatepage.title} />
                      
                       
                        <p onClick={() => {navigate(`/Photocertificate/${certificatepage.id}`)}} type="button" >
                                          
                                  

                          
                           <center>
                            <h6 className="card-text mb-0">{certificatepage.title}</h6>
                            </center>
                  
                           
                         

                            
                            </p>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={certificatepage.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Certificatepage;