import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'





function Aboutpage() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [about, setAbout] = useState([])
    const navigate = useNavigate()

    const aboutCollectionRef = collection(db, "About");
    useEffect(() => {

        const getAbout = async () => {
            const data = await getDocs(aboutCollectionRef);
            setAbout(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAbout()
    }, [])
    return (

        <>




            <br />
            <br />

            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {about.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            }
                            {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((ssagroindiaabout) => {
                            return (
                                <div className="col-md-12">
                      
                      <div class="card mb-3" >
  <div class="row g-0">
    <div class="col-md-6 me-5">
      <img src={ssagroindiaabout.img} class="img-fluid rounded-start" alt="ssagroindia"/>
    </div>
    <div class="col-md-5">
      <div class="card-body">
        <h3 className="card-title mb-4 texts">{ssagroindiaabout.title}</h3>
        <p className="card-text texts"> {ssagroindiaabout.des}</p>

      </div>
    </div>
  </div>
</div>

                                </div>
                             

                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Aboutpage;


