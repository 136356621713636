

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDqWIiUDlzvkU3L8cXWegGu5cCcpzraWFk",
    authDomain: "ssagro-india.firebaseapp.com",
    projectId: "ssagro-india",
    storageBucket: "ssagro-india.appspot.com",
    messagingSenderId: "128438125485",
    appId: "1:128438125485:web:0c58dded157f6637325724",
    measurementId: "G-JBCJMHE9LN"
 
};

//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;