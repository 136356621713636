import React from 'react'
import Navbar  from '../inc/Navbar'
import potatoimage from "../images/potatoimage.jpg"
function Potato() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <img src={potatoimage} className="card-img-top" alt="ssagroindiapvtltd-potatoimage" />
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Indian Potato Exporters</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts mb-3'>Potatoes, known locally as "aloo" in India, hold a special place in the hearts and kitchens of
millions.Beyond its culinary appeal, the potato is a nutritional powerhouse. Packed with essential
vitamins, minerals, and dietary fiber, it provides a wholesome addition to the Indian diet. Potatoes
are not only a source of energy but also contribute vital nutrients, making them a sensible choice
for maintaining a balanced and nutritious lifestyle.From street food to elaborate feasts, the
potato's presence is ubiquitous, making it an essential ingredient in Indian cooking.
</p>
<br/>
<h4 className='texts mb-2'>A.Vitelotte Potato:-</h4>
<p>“Vitelotte" refers to a variety of potato, specifically Solanum tuberosum, commonly known as thepurple potato or blue potato. The Vitelotte potato is known for its vibrant purple or blue skin and
flesh. The color comes from the presence of anthocyanins, which are natural pigments that also
have antioxidant properties.
</p>
<br/>
<br/>
<h4 className='mb-2'>B.Kennebec potato</h4>
<p className='texts mb-3'>The “Kennebec Potato” is a popular cultivar of potato (Solanum tuberosum) that is widely grown
for its versatility in the kitchen. Kennebec potatoes are known for their large size, smooth skin,
and white flesh. It was first developed in the late 19th century by the USDA and Maine Agricultural
Experiment Station. The potato is named after the Kennebec River, which runs through the state
of Maine in the United States.
</p>
<h4>C.Yukon Gold Potato:-</h4>
<p className='texts mb-3'>Yukon Gold Potato is a popular variety of potato known for its smooth, thin, and light yellowishbrown skin, as well as its rich, buttery flavor. It was developed in the 1960s by Canadian
researchers at the University of Guelph in Ontario, Canada. The Yukon Gold potato is a crossbreed
between a North American white potato and a wild South American yellow-fleshed potato.</p>
<h4>D.Ratte potato:-</h4>
<p className='texts mb-3'>It seems like there might be a slight misspelling in your query. If you're referring to "ratte
potatoes," they are a type of small, waxy potato with a unique nutty flavor and firm texture. Ratte
potatoes are often used in French cuisine and can be roasted, boiled, or used in salads.</p>
<h4>E.Russet Burbank potato:-</h4>

<p className='texts mb-3'>The Russet Burbank potato is a widely cultivated and popular variety of potato. It is known for its
large size, oblong shape, and russeted brown skin. The flesh is typically white and has a dry,
starchy texture, making it suitable for a variety of culinary uses.The flesh of the Russet Burbank is
dry and starchy, making it ideal for baking, frying, and mashing. It is often preferred for making
fluffy mashed potatoes and crispy French fries.These potatoes are a staple in many households
and are a key ingredient in numerous dishes around the world. Their versatility and ability to hold
up well to various cooking methods contribute to their enduring popularity in the culinary world.</p>
<h4>F.Bintje potato :-</h4>
<p className='texts mb-3'>The Bintje potato is a popular cultivar of potato that originated in the Netherlands. It was first bred in 1905 by
a Dutch botanist named K.L. de Vries. The Bintje potato is known for its versatility and is commonly used for
French fries and mashed potatoes. Bintje potatoes are usually medium to large in size and have an oval shape.
The skin is light brown, and the flesh is pale yellow. Bintje potatoes have a mild, earthy flavor. While it is versatile
and can be used for various potato dishes, Bintje potatoes are particularly popular for making French fries due
to their texture and taste. It's worth noting that potato varieties can vary in taste and texture, and the popularity
of specific varieties can depend on regional preferences and culinary applications.</p>
<h4>G.King Edward potato:-</h4>
<p className='texts mb-3'>King Edward is a type of potato rather than an actual person or entity. King Edward is a well-known and widely
grown potato variety, particularly in the United Kingdom. It is a floury-textured potato with a creamy-white
flesh and a light red skin. The King Edward potato is often used for baking, mashing, and roasting due to its
fluffy texture and delicious taste.
</p>
<h4>H. Solanum tuberosum potato:-</h4>
<p className='texts mb-3'>Solanum tuberosum is the scientific name for the common potato. Potatoes are a staple food in many parts of
the world and are grown for their edible tubers, which are rich in carbohydrates. They belong to the Solanaceae
family, which also includes tomatoes, eggplants, and peppers. Potatoes are also a good source of nutrients like
vitamin C, vitamin B6, potassium, and dietary fiber. They play a significant role in the diet of many cultures and
are an important crop in agriculture.</p>
<h4>I.Russian Blue potato:-</h4>
<p className='texts mb-3'>"Russian Blue" refers to a specific variety of potato, it might have unique characteristics in terms of color, taste,
or texture. Potatoes can come in various colors, including blue, purple, red, yellow, and white. Each variety may
have distinct flavors and textures when cooked</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Potato