import React from 'react'
import Navbar  from '../inc/Navbar'
function Onion() {
  return (
    <div>
        <Navbar/>
        <br/>
   
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >ONION SPROUTS</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>West Bengal, a state in eastern India, is known for its diverse agricultural practices and the cultivation
of various crops, including peas. While there may not be specific types of peas exclusive to West
Bengal, the region commonly cultivates and consumes different varieties of peas commonly found in
India. Here are some types of peas that are commonly grown and consumed in West Bengal:
</p>
<h4>1. Red Onion:

</h4>
<br/>

<p className='texts'>Red onions are popular for their mild to sweet flavor and vibrant color. They can be used for sprouting, and
the sprouts may have a milder taste compared to the mature onion.
</p>
<h4>2. Yellow Onion:</h4>

<p className='texts'>Yellow onions are known for their strong, pungent flavor. When sprouted, the flavor might be more subtle
compared to the mature onion.
</p>
<h4>3. White Onion: </h4>

<p className='texts'>White onions have a milder flavor compared to yellow onions. White onion sprouts may offer a mild and
slightly sweet taste.</p>

<br/>




        </div>
        </div>
        </div>
    </div>
  )
}

export default Onion