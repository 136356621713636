import React from 'react'
import Navbar  from '../inc/Navbar'
function Peas() {
  return (
    <div>
        <Navbar/>
        <br/>
   
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Peas</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>West Bengal, a state in eastern India, is known for its diverse agricultural practices and the cultivation
of various crops, including peas. While there may not be specific types of peas exclusive to West
Bengal, the region commonly cultivates and consumes different varieties of peas commonly found in
India. Here are some types of peas that are commonly grown and consumed in West Bengal:
</p>
<h4>1. Green Peas (Matar):

</h4>
<br/>

<p className='texts'>Green peas are a popular variety cultivated and consumed across India, including West Bengal. They
are commonly used in various dishes, such as curries, rice preparations, and snacks.</p>
<h4>2. Yellow Peas (Pattani): </h4>

<p className='texts'>Yellow peas are another variety of peas that are cultivated in West Bengal. They are often used in a
variety of traditional Indian dishes, including soups, stews, and curries.
</p>
<h4>3. Black-eyed Peas (Lobia):  </h4>

<p className='texts'>Black-eyed peas are a type of legume commonly cultivated in West Bengal. They are used in various
regional dishes and are known for their distinctive appearance with a small black spot.</p>

<h4>4. Cowpeas (Chaulai): </h4>

<p className='texts'>Cowpeas, also known as chaulai or lal chawli, are grown in West Bengal. They are used in both dry
and curry preparations and are known for their nutritional value</p>
<h4>5. Snow Peas (Sugar Snap Peas): </h4>
<p className='texts'>While not as common as some other varieties, snow peas are also cultivated in certain regions of
West Bengal. They are often used in stir-fries and salads.</p>


<h4>6. Pea Shoots:</h4>
<p className='texts'>In addition to mature peas, the tender shoots of pea plants are also consumed in West Bengal. They
are used in salads and various culinary preparations.</p>
<p className='texts'><small>It's important to note that the availability of pea varieties may vary based on factors such as local
climate, soil conditions, and agricultural practices. Additionally, farmers may choose to cultivate
different pea varieties based on market demand and preferences.</small></p>
<br/>




        </div>
        </div>
        </div>
    </div>
  )
}

export default Peas