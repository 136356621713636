


import React, { useEffect, useState } from 'react'


import { collection, getDoc,  doc, } from 'firebase/firestore'
import { db } from '../../firebase'
import { Link, useParams } from 'react-router-dom'
import Navbar from '../inc/Navbar';




function DocConsult() {
   
   
    const userinfoRef = collection(db, "Appointments") 

  

    const [certificatepage, setCertificatepage] = useState()
    const params = useParams()
    useEffect(() => {
        getData()
    }, [])
    async function getData() {
        try {
            const certificatepageTemp = await getDoc(doc(db,'Certificatepage',params.docId))
            setCertificatepage(certificatepageTemp.data())
            
        } catch(error) {
            console.log(error.message)
        }
    }

   
    return (
    <>
   <Navbar/>
   <br/>
   <br/>
   <br/>
   <br/>
   <div className="album py-0">
        
        <div className="container">

            <div className="row">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li style={{color:"blue"}} className="breadcrumb-item"><Link style={{color:"grey"}} to="/Certificatepage" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg> BACK</Link></li>
    <li style={{color:"blue"}} className="breadcrumb-item"><Link style={{color:"blue"}} to="/aboutus" >About Us</Link></li>
    <li style={{color:"blue"}} className="breadcrumb-item"><Link style={{color:"blue"}} to="/contactus" >Contact</Link></li>
    <li className="breadcrumb-item active" style={{color:"grey"}} aria-current="page">Blog Page</li>
  </ol>
</nav>
</div>
</div>
</div>

       <div className='container-sm' >

            <div className='DocConsultant' style={{ margin: 0 }}>
        {/* <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>

            <div className='DocConsultant' style={{ display: "block",margin:50 }}> */}
    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-12">
            <h4 style={{color:"grey"}} className="card-text mb-4"> {certificatepage && certificatepage.title} </h4>
                        <div className="card mb-4 box ">
                        <img className="card-img-top"  src={certificatepage && certificatepage.img} alt="cetificate" />
                   
                        <div className="card-body">
                {/* <Image style={{ display: "block", width: 350, height: 250 }} src={doctor && doctor.docImg} rounded/> */}
           
             
                <p style={{color:"grey"}} className="card-text"> {certificatepage && certificatepage.des}</p>
            
           
               
               
                
</div>
</div>
</div>
</div>
</div>
</div> 
            </div>
          
                </div>
        
    </>
    )
}

export default DocConsult