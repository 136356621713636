import React from 'react'
import Navbar  from '../inc/Navbar'
function Carrot() {
  return (
    <div>
        <Navbar/>
        <br/>
   
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Mango</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>West Bengal, a state in eastern India, is known for its diverse varieties of mangoes. The state
has a favorable climate for mango cultivation, and different regions produce distinct types of
mangoes.There are numerous varieties of mangoes grown around the world, each with its
own unique flavor, texture, and appearance. Here are some well-known types of mangoes:</p>
<h4>1. Alphonso (Hapus):
</h4>
<br/>

<p>Known as the "king of mangoes," Alphonso is a premium variety with a rich, creamy texture
and a distinct, sweet flavor. It is primarily grown in the Ratnagiri region of Maharashtra,
India.
</p>
<h4>2. Himsagar:
</h4>
<p>Himsagar is one of the most prized varieties of mangoes in West Bengal. It is known for its
sweet taste, aroma, and smooth texture. Himsagar mangoes are usually large, oblong, and
have a thin skin.</p>
<h4>3. Langra: </h4>

<p>Langra is another famous variety of mango from West Bengal. It is known for its unique taste
and fragrance. The mango is medium to large in size and has a greenish-yellow skin. Langra
mangoes are preferred for their excellent flavor.</p>

<h4>4. Fazli (also known as Fazlee or Banganapalli):</h4>

<p>Fazli mangoes are large, elongated, and have a distinct taste. They are widely cultivated in
the Malda district of West Bengal. Fazli mangoes are often used for making mango pulp and
chutneys.</p>
<h4>5. Bombai:</h4>
<p>Bombai mangoes are medium to large in size and have a sweet and slightly tangy taste.
They are grown in various regions of West Bengal and are enjoyed as a table fruit or in the
form of juices and desserts.</p>
<h4>6. Dussehri: </h4>
<p>Although Dussehri is more commonly associated with North India, it is also grown in parts of
West Bengal. This variety is small to medium in size, with a sweet and tangy flavor.</p>

<h4>7. Amrapali:</h4>
<p>Amrapali is a hybrid variety developed in West Bengal. It is a medium-sized mango with a
bright red blush over its skin. Amrapali mangoes are known for their sweet taste and are
often used for both eating fresh and processing.
</p>
<h4>8. Gopalbhog:</h4>
<p>Gopalbhog is a local variety of mango that is grown in West Bengal. It is a small to mediumsized mango with a sweet and aromatic flavor. Gopalbhog mangoes are popular for their
distinct taste.</p>

<h4>9. Khirsapati: </h4>
<p>Khirsapati mangoes are known for their creamy texture and sweet taste. They are medium
to large in size and have a unique flavor. This variety is cultivated in parts of West Bengal.</p>

<p><small>These are just a few examples,there may be other local varieties and hybrids grown in different
regions of West Bengal. Mango season in West Bengal typically peaks during the summer months, and
these varieties are eagerly awaited by mango enthusiasts and connoisseurs.</small></p>

        </div>
        </div>
        </div>
    </div>
  )
}

export default Carrot