import React from 'react'
import Navbar  from '../inc/Navbar'
import Capsicumphoto from "../images/capsicumimage.jpg"
function Capsicum() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <img src={Capsicumphoto} className="card-img-top" alt="ssagroindiapvtltd" />
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Indian Capsicum Exporters</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>capsicum, also known as bell pepper or Shimla Mirch in Hindi, is a vibrant and versatile vegetable
that has earned its place in Indian cuisine for both its vibrant colors and distinctive flavor. This
popular vegetable belongs to the nightshade family and comes in various hues, including green,
red, yellow, and orange, adding a visual feast to Indian dishes. Capsicum is a culinary gem that
adds color, flavor, and nutritional value to Indian dishes. Whether you're preparing a spicy curry,
a hearty stir-fry, or a stuffed delicacy, the versatility of capsicum makes it an essential ingredient
in Indian cooking, celebrated for its taste and visual appeal.
</p>
<br/>
<br/>
<h4>A. Green capsicum:</h4>
<p className='texts mb-3'>Green capsicum, also known as green bell pepper or sweet pepper, is a variety of Capsicum
annuum, which is the same species that includes other peppers like red, yellow, and orange bell
peppers, as well as spicy peppers like chili peppers.Like other bell peppers, green capsicums are
rich in vitamins, especially vitamin C, and also contain significant amounts of vitamin A and
various antioxidants. They are low in calories and are a good source of dietary fiber.Green
capsicums pair well with a variety of ingredients, including onions, tomatoes, garlic, meats, and
other vegetables. They are a common ingredient in many cuisines around the world.
</p>
<h4>B. Yellow capsicum:-</h4>
<p  className='texts mb-3'>Yellow capsicum, also known as yellow bell pepper, is a variety of Capsicum annuum, which is the
same species that includes green, red, and orange bell peppers. The primary difference among
these varieties is their stage of ripeness. Yellow bell peppers are harvested when they reach full
maturity and turn yellow, whereas green bell peppers are harvested before they mature and
change color.The nutritional content of yellow bell peppers contributes to several health benefits,
including supporting the immune system, promoting healthy skin, and providing antioxidants that
may help protect cells from damage</p>
<h4>C. Red capsicum:-</h4>
<p className='texts mb-3'>Red capsicum, also known as red bell pepper, is a type of sweet pepper that belongs to the
Capsicum annuum species. It is characterized by its vibrant red color, sweet taste, and crisp
texture.Red capsicums are an excellent source of vitamins, particularly vitamin C, which is
essential for immune function and skin health. They also contain vitamin A, vitamin B6, folate,
and dietary fiber.Red capsicums in your diet may contribute to various health benefits, such as
improved eye health, reduced risk of certain chronic diseases, and support for a healthy immune
system.</p>
<h4>D. Orange Capsicum:-</h4>
<p className='texts mb-3'>The term "orange capsicum" likely refers to a type of bell pepper, also known as sweet pepper or
capsicum, that is orange in color. Bell peppers come in various colors, including green, yellow,
orange, and red. Each color represents a different stage of ripeness.
Orange bell peppers are typically fully ripe and have a sweet flavor. They are a good source of
vitamins A and C, as well as other nutrients. These peppers can be used in a variety of culinary
applications, such as salads, stir-fries, and stuffed pepper recipes.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Capsicum