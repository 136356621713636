import React from 'react';
import Navbar from '../inc/Navbar';
// import AddBook from '../AddBook';
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
import "./Contactus.css";
import { Link } from "react-router-dom";
import AddContact from '../AddContact';
import Mapg from './Mapg';

function Contactus() {
    return (

        <>
            <Navbar />
            <br />
            <br />

            <ScrollToTop smooth top="100" color="maroon" />


            <div className="ssagroindiapvtltd">
               
                <div className="album py-0">

                    <div className="container">

                        <div className="row">
                            <h1><b>Uniseeds Agro India Export Pvt. Ltd.</b></h1><br />
                            <nav aria-label="breadcrumb">
                                <ol style={{color:"white"}} className="breadcrumb">
                                    <li style={{ color: "white" }} className="breadcrumb-item"><Link style={{ color: "white" }} to="/" ><b>Home</b></Link></li>
                                    <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
            <div className="ssagro">
                <br />

                <AddContact/>
                <br/>
                <br/>
                <br/>
                <Mapg/>
                <br/>
                <br/>
                {/* <center><h2>Our Branches</h2></center>
                <br/>
                <div className="album py-3">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-md-5" >

                                <h4 style={{ color: "light" }} className="card-text mb-3">NEW DELHI</h4>
                           
                                <p className="card-text mb-1">XXX....City </p>
                                <p className="card-text">WB, Dist-.......Pin............(India)</p>
                             



                                <div className="d-flex justify-content-between align-items-center">


                                </div>

                            </div>
                            <div className="col-md-4 mt-6" >

                            
                            <h4 style={{ color: "light" }} className="card-text mb-3">MUMBAI</h4>
                           
                           <p className="card-text mb-1">XXX....City </p>
                           <p className="card-text">WB, Dist-.......Pin............(India)</p>
                        
                                
                                <div className="d-flex justify-content-between align-items-center">

                                </div>

                            </div>
                            <div className="col-md-3 " >
                            <h4 style={{ color: "light" }} className="card-text mb-3">KOLKATA</h4>
                           
                           <p className="card-text mb-1">XXX....City </p>
                           <p className="card-text">WB, Dist-.......Pin............(India)</p>



                                <div className="d-flex justify-content-between align-items-center">

                                </div>

                            </div>
                        </div>
                        <br/>
                     
                    </div>
                </div> */}

                
            

            </div>



        </>

    );
}

export default Contactus;