
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./components/pages/Home";
import Contactus from './components/pages/Contactus';
import Footer from './components/inc/Footer';
import Potato from './components/pages/Potato';
import Certificate from './components/pages/Certificate';
import Aboutus from './components/pages/Aboutus';
import Rice from './components/pages/Rice';
import Mushroom from './components/pages/Mushroom';
import Capsicum from './components/pages/Capcicum';

import Blogpage from './components/pages/Blogpage';
import DocBlog from './components/pages/DocBlog';
import DocCertificate from './components/pages/DocCertificate';
import Carrot from './components/pages/Carrot';
import Pumpkin from './components/pages/Pumpkin';
import Mango from './components/pages/Mango';
import Subscribe from './components/pages/Subscribe';

import Certificatepage from './components/pages/Certificatepage';
import Rasogolla from './components/pages/Rasogolla';
import Chili from './components/pages/Chili';
import Beans from './components/pages/Beans';
import Peas from './components/pages/Peas';
import Drumstick from './components/pages/Dumstrick';
import Onion from './components/pages/Onion';

function App() {
  return (
    <Router>

      <div>

       
        
        <Routes>

        <Route axact path="/" element={<Home/>}/>
        <Route axact path="/contactus" element={<Contactus/>}/>
        <Route axact path="/potato" element={<Potato/>}/>
        <Route axact path="/certificate" element={<Certificate/>}/>
        <Route axact path="/aboutus" element={<Aboutus/>}/>
        <Route axact path="/rice" element={<Rice/>}/>
        <Route axact path="/mushroom" element={<Mushroom/>}/>
        <Route axact path="/capsicum" element={<Capsicum/>}/>
        <Route axact path="/carrot" element={<Carrot/>}/>
        <Route axact path="/pumpkin" element={<Pumpkin/>}/>
        <Route axact path="/subscribe" element={<Subscribe/>}/>
        <Route axact path="/blog" element={<Blogpage/>}/>
        <Route axact path="/rasogolla" element={<Rasogolla/>}/>
        <Route axact path="/chili" element={<Chili/>}/>
        <Route axact path="/mango" element={<Mango/>}/>
        <Route axact path="/beans" element={<Beans/>}/>
        <Route axact path="/Peas" element={<Peas/>}/>
        <Route axact path="/Drumstick" element={<Drumstick/>}/>
        <Route axact path="/Onion" element={<Onion/>}/>
       

        <Route axact path="/Certificatepage" element={<Certificatepage/>}/>

        <Route path="/Photo/:docId" element={<DocBlog/>}/>
        <Route path="/Photocertificate/:docId" element={<DocCertificate/>}/>
      
        
        </Routes>
    
  <Footer/>
    </div>
   
    </Router>
  );
}

export default App;
