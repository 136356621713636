import React from 'react'
import Navbar  from '../inc/Navbar'
import Mushroomp from "../images/mushroompage.jpg"
function Mushroom() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <img src={Mushroomp} className="card-img-top" alt="ssagroindiapvtltd-potatoimage" />
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Indian Mushroom Exporters</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>Mushrooms, often referred to as nature's hidden gems, have been a culinary and medicinal
delight for centuries. Beyond their culinary appeal, mushrooms are recognized for their
nutritional and medicinal benefits. Rich in essential nutrients, they contribute to a well-balanced
diet. Mushrooms are a great source of protein, vitamins, and minerals, making them an excellent
choice for those seeking nutritious alternatives. Additionally, certain varieties of mushrooms are
known for their immune-boosting and antioxidant properties, aligning with traditional Indian
Ayurvedic principles of holistic well-being. In the realm of Indian culinary and medicinal
traditions, mushrooms stand out as a remarkable ingredient. As awareness of their benefits
continues to grow, mushrooms are sure to remain a cherished and integral part of India's diverse
and vibrant food culture.</p>
<h4>1.White button mushrooms</h4>
<p className='texts'>White button mushrooms (Agaricus bisporus) are one of the most common and widely consumed
mushrooms worldwide. They are small to medium-sized with a smooth, convex cap that ranges
in color from white to cream. The cap can measure anywhere from 1 to 5 centimeters in diameter.
As they mature, the cap may open up, exposing the gills beneath. White button mushrooms have
a mild, delicate flavor that intensifies when cooked. They have a firm texture and are commonly
used in various culinary applications. White button mushrooms can be enjoyed raw in salads or
cooked in various dishes such as stir-fries, soups, stews, and more. They can be sautéed, grilled,
roasted, or added to sauces. These mushrooms are versatile and can complement a wide range
of dishes. They are often used in both vegetarian and non-vegetarian recipes to add flavor and
texture.
</p>
<h4>2.Closed cup mushrooms:-</h4>
<p className='texts' >Closed cup mushrooms, also known as white mushrooms or button mushrooms, are one of the
most commonly consumed mushrooms worldwide. These mushrooms have a mild flavor and a
firm texture, making them versatile for use in various dishes such as salads, stir-fries, soups, and
more. They are often sold when the caps are still closed or only slightly opened, hence the name
"closed cup."
</p>
<h4>3. Open cup mushroom:-</h4>
<p className='texts'>Open cup mushrooms, also known as flat mushrooms or field mushrooms, are a variety of mushrooms with a
flat, open cap. These mushrooms belong to the Agaricus bisporus species, which also includes white button
mushrooms and chestnut mushrooms. The open cup mushrooms have a broader cap compared to the button
mushrooms and are known for their rich, savory flavor. The cap of an open cup mushroom is flat and can vary
in color from white to light brown. These mushrooms are versatile and can be used in various dishes, such as
sautés, soups, stews, and stir-fries. They are also great for grilling and roasting.</p>
<h4>4. chestnut mushroom:-</h4>
<p className='texts'>Chestnut Mushroom is a type of mushroom known for its nutty flavor and firm texture. One
common variety is the "cremini" mushroom, which is similar to the white button mushroom but
has a more robust flavor. Chestnut mushrooms are often used in various culinary dishes, such as
stir-fries, soups, salads, and pasta dishes. They pair well with a variety of ingredients and can add
a unique taste and texture to your recipes.</p>
<h4>5. Portobello mushroom:-</h4>
<p className='texts'>Portobello mushrooms are a type of large, mature button mushroom with a firm texture and a
robust, meaty flavor. They are a popular choice for various culinary applications, including grilling,
roasting, stuffing, or using as a meat substitute in vegetarian dishes.</p>
<h4>6. Shiitake mushroom</h4>
<p className='texts'>Shiitake mushrooms is a popular and flavorful variety of mushrooms commonly used in Asian
cuisine. Shiitake mushrooms have a distinct savory taste and a meaty texture. They are often used
in stir-fries, soups, and other dishes.</p>
<h4>7. Oyster mushrooms:-</h4>
<p className='texts'>Oyster mushrooms (Pleurotus ostreatus) are a type of edible mushroom that is widely cultivated
and consumed around the world. Oyster mushrooms have a distinct oyster-shaped cap, which
can vary in color from white to shades of pink, yellow, or brown. The gills are white and descend
down the stem. Oyster mushrooms are a good source of protein, fiber, vitamins (such as B
vitamins), and minerals (such as iron and potassium). They are also low in calories and fat. Oyster
mushrooms are a good source of protein, fiber, vitamins (such as B vitamins), and minerals (such
as iron and potassium). They are also low in calories and fat.
</p>
<h4>8. King oyster mushroom:-</h4>
<p className='texts'>"King oyster mushrooms," they are a type of edible mushroom that is popular in various cuisines.
King oyster mushrooms (scientific name: Pleurotus eryngii) have a firm texture and a mild, savory
flavor. King oyster mushrooms are versatile and can be used in various dishes, including stir-fries,
soups, and risottos. Their meaty texture makes them a popular choice for plant-based or
vegetarian recipes.
</p>
<h4>9.Porcini mushroom:-</h4>
<p className='texts'>Porcini mushroom are a type of wild mushroom that is highly regarded in culinary circles for their rich, nutty
flavor. They are often used in various dishes, including soups, risottos, pasta dishes, and sauces.
</p>
<h5>Enoki mushroom</h5>
<p className='texts'>Enoki mushrooms, also known as enokitake or enokidake, are a type of long, thin white
mushroom with small caps. They have a delicate flavor and a crunchy texture, making
them a popular ingredient in various Asian cuisines. As with any food, individual reactions
may vary. While enoki mushrooms are generally safe to eat, people with mushroom
allergies should exercise caution.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Mushroom