import React from 'react'
import Navbar  from '../inc/Navbar'
function Drumstick() {
  return (
    <div>
        <Navbar/>
        <br/>
   
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Drumstick(Sojne Data):-</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>It seems like there might be a bit of confusion or a typo in your question. "Sojne Data"
doesn't appear to be a standard term related to drumsticks or any common culinary
ingredient. However, if you are referring to drumsticks as a food item, it could mean
different things in various cuisines.
</p>
<h4>1. Vegetable (Moringa oleifera):-
</h4>
<br/>

<p className='texts'>In some regions, "drumstick" refers to the long, slender green pods of the moringa tree.
These are commonly used in South Asian cuisine and are known for their nutritional value.</p>
<h4>2. Poultry:-</h4>

<p className='texts'>In Western cuisine, "drumsticks" often refer to the lower part of a chicken or turkey leg.
These are commonly roasted, grilled, or fried.
</p>




<br/>




        </div>
        </div>
        </div>
    </div>
  )
}

export default Drumstick