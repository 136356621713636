import React from 'react'
import Navbar  from '../inc/Navbar'
import Carrotpage from "../images/carrotpage.jpg"
function Carrot() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <img src={Carrotpage} className="card-img-top" alt="ssagroindiapvtltd-potatoimage" />
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h1 style={{color:"green"}} >Indian Carrot Exporters</h1></center>
        <br/>
      
        <div className='album py-2'>
          <div className='container'>
            <div className='row'>
        <p className='texts'>Carrots, known locally as "gajar" in India, are a vibrant and versatile vegetable that holds a special
place in Indian cuisine. Bursting with color and flavor, carrots are not only a popular choice for
their natural sweetness but also for their numerous health benefits.Beyond their delicious taste,
carrots are a powerhouse of nutrients. Rich in beta-carotene, they contribute to good eye health
and provide antioxidant properties. Carrots are also known for promoting skin health and
boosting the immune system, making them an essential part of a balanced diet.Whether enjoyed
as a snack, a side dish, or the star of a culinary creation, carrots bring a burst of color and nutrition
to Indian kitchens. Embrace the versatility of this humble vegetable and explore the myriad ways
it can elevate the flavors and health quotient of your meals.</p>
<h4>1. Pusa Kesar Carrot:-
</h4>
<p>"Pusa Kesar Carrot" suggests that it might be associated with the Indian Agricultural Research
Institute (IARI), which often names its crop varieties with the prefix "Pusa." The term "Kesar"
could be a descriptor, possibly indicating a characteristic like color, taste, or aroma.
</p>
<h4>2. Early Nantes carrot:-
</h4>
<p>The Early Nantes carrot is a popular variety of carrot known for its sweet and tender flavor. It is a
type of heirloom carrot that is cylindrical in shape with a smooth skin and a vibrant orange
color.Early Nantes carrots are prized for their sweet and mild flavor. The early harvest often
contributes to their tenderness.</p>
<h5>3. The Chantenay carrot is a variety of carrot known for its distinctive shape and flavor. Unlike the
typical long and slender carrot, Chantenay carrots are shorter and broader, with a conical shape
and a blunt end. They are usually about 5 to 7 inches in length.</h5>
<p>One of the key characteristics of Chantenay carrots is their excellent sweet and crisp taste. They
are often preferred for cooking, as they hold their shape well and have a robust flavor when
roasted or used in stews and soups. The color of Chantenay carrots can range from orange to
deep red, and they are rich in beta-carotene, providing health benefits.Chantenay carrots have
been a popular choice for culinary purposes due to their unique attributes and versatility in
various dishes.</p>
<h4>4. Little Fingers carrots :-</h4>

<p>Little Fingers carrots are a smaller variety of carrot with a slender shape, typically around 3-4
inches long.They are sweet and tender, making them a popular choice for fresh eating, salads,
and snacks.Little Fingers carrots are suitable for container gardening and are known for their quick
growth.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Carrot